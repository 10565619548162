/* global styles */
h1{
    margin: 0px;
    font-size: 38px;
}
h2, h3, h4, p{
    margin:0px;
}
h5{
    margin: 3px 0px;
}
hr{
    margin: 0px;
    border-style: solid;
}
p{
    font-size: 10px;
}
ul{
    padding: 0px;
    margin: 0px 0px 0px 22px;
    list-style-type: circle;
}
span{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}
.spacey{
    letter-spacing: 3px;
}
.italic{
    font-style: italic;
}
.inset {
    margin: 0 20px 5px;
}
a {
    text-decoration: none;
}
/* a:hover, */
button:hover{
    cursor: pointer;
}
#App{
    overflow: hidden;
}
#main{
    position: relative;
    min-height: 100vh;
    min-width: 100vw;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: .5fr 4.5fr 100px;
    align-items: start;
    justify-items: center;
    gap: 50px;
}


/* toggle switch (adapted from W3schools) */
#switchDiv{
    float: right;
    margin: 10px 10px 0 0;
}
.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 28px;
}
.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color:  #bfbcbc;
    -webkit-transition: .4s;
    transition: .4s;
}


/* header */
#header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    padding-bottom: 36px;
}
.nameDiv{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.name{
    margin: 0px;
    font-family: 'Gloock';
}
.bigV{
    font-size: 45px;
    font-family: 'Gloock';
    margin: 0px -9px -1px 0px;
}
#title{
    font-family: 'Outfit';
    font-size: 18px;
    margin: -4px 0px 22px 0px;
    letter-spacing: 3px;
    font-weight: 500;
}
/* header buttons */
#tabs{
    width: 321px;
    display: flex;
    justify-content: space-between;
}
.tab{
    font-family: 'Outfit';
    font-size: 13px;
    height: 23px;
    letter-spacing: 3px;
    padding: 0px;
    margin-bottom: 0px;
}
.tab:hover{
    cursor: pointer;
}
.Dselected{
    color: #73aae2;
    font-weight: 200;
}
.Lselected{
    color: #547ca6;
    font-weight: 200;
}
.rLine{
    width: 62px;
}


/* Middle pane */
#resumeDiv{ /* resume */
    display: flex;
    flex-direction: column;
    gap: 60px;
    max-width: 950px;
    padding: 0 10vw;
}
.resSubDiv{
    font-family: 'Gloock';
    letter-spacing: 4px;
    margin-bottom: 12px;
}
.rowSubdivItem {
    min-width: 50%;
}
.resBody{
    font-family: 'Outfit';
    font-weight: 300;
}
.bold{
    font-weight: 500;
    letter-spacing: 2px;
}
.topLine{
    display: flex;
    align-items: center;
}
.tallLine{
    font-family: courier;
    display: block;
    font-weight: 100;
    font-size: 18px;
}
.job{
    width: 430px;
}
.buttonRow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.courseButton,
.courseButtonD{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 30px;
    padding: 10px;
    border-radius: 6px;
    margin-top: 10px;
    border: none;
}
.courseButtonD{
    color: white;
    background-color: #4b5d70;
}
.courseButton{
    color: black;
    background-color: #b6c7da;
}
.courseButtonD:hover{
    box-shadow: 4px 4px 4px 0px black;
}
.courseButton:hover{
    box-shadow: 4px 4px 4px 0px #8b8888;
}
.courseIcon{
    width: 30px;
    height: 30px;
}
.courseDescription{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
}
/* projects */
#workDiv{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-items: center;
    gap: 90px;
    align-items: center;
}
.row {
    display: flex;
    flex-wrap: wrap;
    width: 90vw;
    gap: 25px;
    justify-content: center;
}
.tile,
.darkTile{
    margin: 0 10px 10px 10px;
    width: 430px;
    border-radius: 6px;
    overflow: hidden;
}
.tile{
    border: 4px solid #363537;
}
.darkTile{
    border: 4px solid #363537;
}
.description{
    display: flex;
    margin: 0 10px 10px 10px;
    width: 430px;
    gap: 10px;
    flex-direction: column;
}
.page{
    width: 100%;
    height: 100%;
    display: block;
}
.ProjBody{
    font-family: 'Outfit';
    font-weight: 300;
    font-size: 16px;
}


/* icons */
.icon{
    width: 50px;
    height: 50px;
}
.footer{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 30px 0px 20px 0px;
}

/* about */
#aboutDiv{
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-items: center;
    justify-content: center;
    gap: 50px;
}
#aboutText{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 458px;
    font-family: 'Outfit';
    font-weight: 300;
    margin-bottom: 50px;
}
.aboutHead{
    font-size: 30px;
}
.aboutBody{
    font-size: 20px;
}
.framedark,
.framelight{
    width: 220px;
}
.framedark{
    border: 8px solid #ffffff;
    padding: 20px;
}
.framelight{
    border: 8px solid #363537;
    padding: 20px;
}
.profile{
    width: 100%;
}



/* transitions */
.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    border-radius: 50%;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
    background-color: #747276;
}
input:focus + .slider {
    box-shadow: 0 0 1px rgb(54, 53, 55);;
}
input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
}